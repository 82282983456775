import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled(Button)(({ theme, customStyles })  => ({
  ...customStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  borderRadius: theme.spacing(6.25),
  overflow: 'hidden',
  boxShadow: theme.shadows.elevation.medium,
  cursor: 'pointer',
  transition: 'all 0.2s ease-out',
  color: theme.palette.font.black,
  textTransform: 'capitalize',
  background: theme.palette.gray[100],
  width: 'fit-content',
  '&:hover': {
    boxShadow: theme.shadows.elevation.large,
    background: theme.palette.gray[100],
  },
}))

const Name = styled(props => <Box {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  padding: theme.spacing(1),
  overflow: 'hidden',
  whiteSpace: 'no-wrap',
  textOverflow: 'ellipsis',
  ...theme.textEllipsis[1],
}))

const MediaMicroCard = ({ customStyles, imageUrl, name, onClick, ...props }) => {


  return <Root onClick={onClick} customStyles={customStyles}>
    <Name>
      {
        name
      }
    </Name>
  </Root>
}

export default MediaMicroCard
