import { useCallback, useEffect, useState } from 'react'

import useAccounting from '@hooks/useAccounting'
import useCurrency from '@hooks/useCurrency'

const useUserCurrencyAccounting = () => {

  const { format } = useAccounting()
  const { currency, getAvailableCurrencies } = useCurrency()
  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    getAvailableCurrencies().then(currencies => {
      setCurrencies(currencies)
    }).catch(e => console.error(e))
  }, [])

  const formatInUserCurrency = useCallback((originalPrice, originalCurrency, formatOptions={}) => {
    if(!currency || !originalCurrency) return
    if(originalCurrency.id === currency.id)
      return format(originalPrice, { endSymbol: currency?.id, ...formatOptions })

    let ogExchange = originalCurrency.exchange
    if(!ogExchange && originalCurrency.id!=='MXN') {
      ogExchange = currencies.find(c => c.id === originalCurrency.id)?.exchange
    } else if (originalCurrency.id === 'MXN') {
      ogExchange = 1
    }
    if(!ogExchange) return

    const priceInUserCurrency = (originalPrice * currency.exchange)/ogExchange
    return format(priceInUserCurrency, { endSymbol: currency?.id, ...formatOptions })
  }, [currency, currencies])

  return {
    formatInUserCurrency,
  }
}

export default useUserCurrencyAccounting
