import { useCallback } from 'react'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'

import useError from '@hooks/useError'
import useTheme from '@hooks/useTheme'
import { getLocationQuery } from '@helpers/location'
import { stringToURL } from '@helpers/urlFormatter'
import { useContextLocation } from '@context/Location'

const Root = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  boxShadow: theme.shadows.elevation.medium,
  cursor: 'pointer',
  color: theme.palette.font.black,
  textTransform: 'capitalize',
  background: theme.palette.gray[100],
  width: '100%',
  minWidth: '120px',
  height: '100px',
  gap: theme.spacing(1),
  transition: 'all 0.2s ease-out',
  '&:hover': {
    boxShadow: theme.shadows.elevation.large,
    background: theme.palette.gray[100],
  },
  [theme.breakpoints.down('md')]:{
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderRadius: theme.spacing(6.25),
    height: '32px',
    minWidth: 'fit-content',
    '&:hover': {
      boxShadow: theme.shadows.elevation.large,
      background: theme.palette.gray[100],
    },
  },
}))

const Name = styled(props => <Box {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '500',
  ...theme.textEllipsis[1],
}))

const CategoryCard = ({ category, highlighted=false, ...props }) => {

  const { theme } = useTheme()
  const router = useRouter()
  const { showAlert } = useError()
  const { location } = useContextLocation()

  const handleClick = useCallback(e => {
    router.push({
      pathname: `/event/search/category/${stringToURL(category.category.name)}/${category.category.id}`,
    }).then(resp => {})
      .catch(err => showAlert(err.toString()))
  }, [router, location])

  return <Root onClick={handleClick} sx={ highlighted ?
    {
      backgroundColor: theme.palette.gray[700],
      color: theme.palette.gray[100],
      '&:hover': {
        background: theme.palette.gray[700],
      },
    }
    : {}}>
    <span className='material-symbols-rounded'>
      { category.iconName.replaceAll(' ', '_') }
    </span>
    <Name>
      {
        category.category.name
      }
    </Name>
  </Root>
}

export default CategoryCard
