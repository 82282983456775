import { styled } from '@mui/material/styles'
import { Dialog, DialogContent, useMediaQuery } from '@mui/material'
import { useGlossary, useTheme } from '@App/hooks'
import WaitlistForm from './WaitlistForm'

const CustomDialogContent = styled(props => <DialogContent {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    padding: 0,
    backgroundColor: theme.palette.gray[100],
  },
}))

const CustomDialog = styled(props => <Dialog {...props} />)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      borderRadius: theme.spacing(1),
    },
  },
}))

const WaitlistModal = ({ open, setOpen, eventDefId, eventDefName, show, showNoTicketsCopy = true }) => {
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CustomDialog
      fullScreen={!isMd}
      open={open}
      onClose={handleClose}
      scroll={'body'}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <CustomDialogContent>
        <WaitlistForm
          handleClose={handleClose}
          eventDefId={eventDefId}
          eventDefName={eventDefName}
          show={show}
          showNoTicketsCopy={showNoTicketsCopy}
        />
      </CustomDialogContent>
    </CustomDialog>
  )
}

export default WaitlistModal
