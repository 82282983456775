import {Box, Link} from '@mui/material'
import { styled } from '@mui/material/styles'

import useGlossary from '@hooks/useGlossary'
import CustomSlider from '@components/CustomSlider'
import HomeLabel from '@components/HomeLabel'
import EventCard from './EventCard'
import EventCardPlaceholder from './EventCardPlaceholder'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
}))

const SliderHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
}))

const CustomLink = styled(props => <Link {...props}/>)(({ theme }) => ({
  color: theme.palette.secondary.main,
  paddingBottom: theme.spacing(1),
}))

const EventsSlider = ({ loading, label, data, onSeeMore, ...props }) => {

  const { glossary } = useGlossary()

  const mediaQueries = [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
  ]

  return <Root>
    <SliderHeader>
      <HomeLabel>
        { label }
      </HomeLabel>
      { onSeeMore &&
        <CustomLink underline='hover' component='button' onClick={() => onSeeMore()}>
          { glossary('SeeMore') }
        </CustomLink>
      }
    </SliderHeader>
    <CustomSlider
      slidesToShow={5}
      infinite={false}
      responsive={mediaQueries}
      lazyLoad={true}
    >
      {
        loading
          ? Array.from(new Array(5)).map((_, index) => (
            <EventCardPlaceholder key={index} />
          ))
          : data.map(item => (
            <EventCard key={item.id} data={item} />
          ))
      }

    </CustomSlider>
  </Root>
}

export default EventsSlider
