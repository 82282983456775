import { styled } from '@mui/material/styles'
import { Card, CardActionArea, CardContent, Box, Skeleton } from '@mui/material'

const Container = styled(props => <Box {...props} />)(({ theme }) => ({
  minWidth: '230px',
}))

const StyledCard = styled(props => <Card {...props} />)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: `${theme.shadows.elevation.medium} !important`,
  transition: 'all 0.2s ease-out',
  height: '366px',
  '&:hover': {
    boxShadow: `${theme.shadows.elevation.large} !important`,
  },
  '&.disableRipple>button>.MuiTouchRipple-root': {
    display: 'none',
  },
}))

const StyledCardActionArea = styled(props => <CardActionArea {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  '&:hover': {
    backgroundColor: theme.palette.gray[100],
  },
  '& .MuiCardActionArea-focusHighlight': {
    display: 'none',
  },
}))

const MediaSkeleton = styled(props => <Skeleton {...props} />)(({ theme }) => ({
  width: '100%',
  aspectRatio: 'calc(7 / 4)',
  height: '100%',
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
}))

const StyledCardContent = styled(props => <CardContent {...props} />)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
}))

const EventCardPlaceholder = ({ ...props }) => {
  return <Container>
    <StyledCard>
      <StyledCardActionArea>
        <MediaSkeleton variant='rect'/>
        <StyledCardContent >
          <Skeleton variant='rect' sx={{ marginBottom:'30px' }}/>
          <Skeleton variant='text'/>
          <Skeleton variant='text'/>
        </StyledCardContent>
        <StyledCardContent sx={{ paddingTop: '36px' }}>
          <Skeleton variant='text'/>
          <Skeleton variant='text'/>
        </StyledCardContent>

      </StyledCardActionArea>
    </StyledCard>
  </Container>
}

export default EventCardPlaceholder
