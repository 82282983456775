import { styled } from '@mui/material/styles'
import { Dialog, Box, DialogTitle } from '@mui/material'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import useGlossary from '@hooks/useGlossary'

const CustomDialogTitle = styled(props => <DialogTitle {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 0,
  '& svg':{
    height: '50px',
    width: '50px',
  },
}))

const Text = styled(props => <Box {...props} />)(({ theme }) => ({
  marginTop: '20px',
  fontWeight: 700,
  fontSize:'small',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize:'medium',
  },
}))

const LoadingModal = ({ open }) => {

  const { glossary } = useGlossary()

  return <Dialog
    fullWidth={false}
    aria-labelledby='customized-dialog-title'
    open={open}
    sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
  >
    <CustomDialogTitle>
      <Player
        autoplay
        loop
        src='/animations/NewSearch.json'
        style={{ maxHeight: '200px', maxWidth: '200px' }}
      >
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']}/>
      </Player>
      <Text>{glossary('SearchingTickets')}</Text>
    </CustomDialogTitle>
  </Dialog>
}

export default LoadingModal
