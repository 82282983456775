import { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, CardMedia, CardActionArea, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'

import baseConfig from '@baseConfig'
import useDate from '@hooks/useDate'
import useError from '@hooks/useError'
import useUserCurrencyAccounting from '@hooks/useUserCurrencyAccounting'
import useEvent from '@hooks/useEvent'
import useGlossary from '@hooks/useGlossary'

import { stringToURL } from '@helpers/urlFormatter'
import BlueButton from '@components/BlueButton'
import CustomChip from '@components/CustomChip'
import LoadingModal from '@features/Events/LoadingModal'
import WaitlistModal from '@features/Waitlist/WaitlistModal'

const StyledCard = styled(props => <Card {...props} />)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: `${theme.shadows.elevation.medium} !important`,
  transition: 'all 0.2s ease-out',
  height: '100%',
  overflow: 'hidden !important',
  '&:hover': {
    boxShadow: `${theme.shadows.elevation.large} !important`,
  },
  '&.disableRipple>button>.MuiTouchRipple-root': {
    display: 'none',
  },
}))

const StyledCardActionArea = styled(props => <CardActionArea {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  '&:hover': {
    backgroundColor: theme.palette.gray[100],
  },
  '& .MuiCardActionArea-focusHighlight': {
    display: 'none',
  },
}))

const StyledCardMedia = styled(props => <CardMedia {...props} />)(({ theme }) => ({
  width: '100%',
  aspectRatio: 'calc(7 / 4)',
  backgroundSize: 'cover !important',
  backgroundPosition: 'center !important',
  backgroundRepeat: 'no-repeat !important',
}))

const StyledCardContent = styled(props => <CardContent {...props} />)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const BackgroundPhoto = styled(props => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.gradient.linear.transparent,
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}))

const Name = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
  fontWeight: '600',
  height: `calc(2 * ${theme.lineHeight.paragraph.large})`,
  ...theme.textEllipsis[2],
}))

const ExtraInfo = styled(props => <Box {...props} />)(({ theme }) => ({
  color: theme.palette.gray[500],
  font: theme.font.rubik.paragraph.small,
  display: 'flex',
  flexDirection: 'column',
  gap: '0',
}))

const Date = styled(props => <Box {...props} />)(({ theme }) => ({
  fontWeight: '500',
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    ...theme.textEllipsis[2],
  },
}))

const Location = styled(props => <Box {...props} />)(({ theme }) => ({
  height: `calc(2 * ${theme.lineHeight.paragraph.small})`,
  ...theme.textEllipsis[2],
}))

const Bottom = styled(props => <Box {...props} />)(({ theme }) => ({
  color: theme.palette.gray[700],
  display: 'flex',
  flexDirection: 'column',
  gap: '0',
  height: 'calc((0.875rem / 0.75) + (1.5rem / 1.25))',
  '& button': {
    height: '100%',
  },
}))

const Label = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.minimum,
}))

const Price = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.medium,
  fontWeight: '600',
}))

const Container = styled(props => <Box {...props} />)(({ theme }) => ({
  minWidth: '230px',
}))

const EventCard = ({ data, waitForUpdate = false, ...props }) => {

  const { glossary } = useGlossary()
  const router = useRouter()
  const { showAlert } = useError()
  const { format: dayFormat } = useDate()
  const { formatInUserCurrency } = useUserCurrencyAccounting()
  const { updatedEventSubscription } = useEvent()

  const [hasBoxEvents, setHasBoxEvents] = useState(data.boxMinimalPrice > 0 || parseInt(data.lowPrice) > 0)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [waitListModal, setWaitListModal] = useState(false)
  const updatedEventData = updatedEventSubscription(data.id)
  const [waitEventId, setWaitEventId] = useState(null)
  const [waitEventName, setWaitEventName] = useState(null)

  const getMinimalPrice = data => {
    let min
    if (data.source === 'ticketero') {
      min = data.lowPrice * (1 + data.profitability / 100)
    } else if (data.source === 'stadibox') {
      min = data.boxMinimalPrice
    } else {
      let ticketMinimalPrice = data.lowPrice * (1 + data.profitability / 100)
      if (data.boxMinimalPrice < 1 || ticketMinimalPrice < 1) {
        min = data.boxMinimalPrice || ticketMinimalPrice
      } else {
        min = Math.min(data.boxMinimalPrice, ticketMinimalPrice)
      }
    }
    return min
  }

  const [minimalPrice, setMinimalPrice] = useState(getMinimalPrice(data))

  const openWaitlist = (eventName, eventId) => {
    setWaitEventName(eventName)
    setWaitEventId(eventId)
    setWaitListModal(true)
  }

  const handleClick = useCallback(e => {
    if (!hasBoxEvents && data.source != 'ticketero') return
    setLoadingModalOpen(true)
    router.push({
      // eslint-disable-next-line max-len
      pathname: `/event/box/explorer/${stringToURL(data.name)}-${stringToURL(data.forum.name)}/${data.id}`,
    }).then(resp => { })
      .catch(err => showAlert(err.toString()))
  }, [router, data, hasBoxEvents])

  useEffect(() => {
    if (updatedEventData) {
      setHasBoxEvents(updatedEventData.boxMinimalPrice > 0 || updatedEventData.lowPrice > 0)
      setMinimalPrice(getMinimalPrice(updatedEventData))
    }
  }, [updatedEventData])

  return <Container onClick={handleClick}>
    <StyledCard className={hasBoxEvents ? '' : 'disableRipple'}>
      <StyledCardActionArea>
        <StyledCardMedia
          component={'div'}
          sx={{ background: `url('${baseConfig.previewRoot}/${data.files[0].id}')` }}
        >
          <BackgroundPhoto>
            {
              data.categories?.length > 0 && <CustomChip
                color={'secondary'}
                style={'classic'}
                label={data.categories[0].name}
              />
            }
          </BackgroundPhoto>
        </StyledCardMedia>
        <StyledCardContent>
          <Name>
            {data.name}
          </Name>
          <ExtraInfo>
            <Date>
              {data.time === 'TBA' ?
                `${dayFormat(data.date, 'ddd, ll', null, data.forum?.region?.timeZone)} - ${data.time}`
                : dayFormat(data.date, 'ddd, ll - LT', null, data.forum?.region?.timeZone)
              }
            </Date>
            <Location>
              {data.forum?.name} - {data.forum?.city?.name ?? data.forum?.region?.name ?? ''},
              {' ' + data.forum?.region?.country?.id}
            </Location>
          </ExtraInfo>
          {
            hasBoxEvents &&
            <Bottom>
              <Label>
                {glossary('PlacesFrom')}:
              </Label>
              <Price>
                {formatInUserCurrency(minimalPrice, data.currency, { templateFormat: '%a %e' })}
              </Price>
            </Bottom>
          }
          {
            (!hasBoxEvents && data.source != 'ticketero') &&
            <Bottom>
              <BlueButton variant='container' color='secondary' onClick={e => openWaitlist(data.name, data.id)}>
                {glossary('WaitList')}
              </BlueButton>
            </Bottom>
          }
        </StyledCardContent>
      </StyledCardActionArea>
    </StyledCard>
    <WaitlistModal open={waitListModal} setOpen={setWaitListModal} eventDefId={waitEventId}
      eventDefName={waitEventName} />
    <LoadingModal open={loadingModalOpen} />
  </Container>
}

export default EventCard
