import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import useEventCategory from '@hooks/useEventCategory'
import useGlossary from '@hooks/useGlossary'
import CustomSlider from '@components/CustomSlider'
import HomeLabel from '@components/HomeLabel'
import CategoryCard from './CategoryCard'

const Root = styled(Box)(({ theme }) => ({}))

const CategoriesContainer = ({ selectedCategory, hideIfSelectedCategoryIsNotTop = false, label, customStyles, ...props }) => {
  const { glossary } = useGlossary()
  const { getTopEventCategories } = useEventCategory()

  const [categories, setCategories] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const items = categories.length

  const mediaQueries = [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 9,
        infinite: items > 9,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 8,
        infinite: items > 8,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 7,
        infinite: items > 7,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        infinite: items > 6,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 5,
        infinite: items > 5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
        infinite: items > 4,
      },
    },
  ]

  const fetchCategories = useCallback(() => {
    getTopEventCategories()
      .then(resp => {
        setCategories(resp)
      })
      .catch(e => setError(true))
      .finally(() => setLoading(false))
  }, [categories, error, loading])

  useEffect(() => {
    fetchCategories()
  }, [])

  return !error &&
    categories.length > 0 &&
    (!hideIfSelectedCategoryIsNotTop || categories.findIndex(c => c.category.id === selectedCategory.id) !== -1) ? (
    <Root>
      <HomeLabel customStyles={customStyles}>{label ?? glossary('Categories')}</HomeLabel>
      <CustomSlider slidesToShow={10} infinite={items > 10} responsive={mediaQueries}>
        {categories.map(category => (
          <CategoryCard
            key={category.id}
            category={category}
            highlighted={selectedCategory ? category.category.id === selectedCategory.id : false}
          />
        ))}
      </CustomSlider>
    </Root>
  ) : (
    <Box />
  )
}

export default CategoriesContainer
