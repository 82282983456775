/* eslint-disable max-len */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, FormControlLabel, RadioGroup, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import baseConfig from '@baseConfig'
import { useError, useGlossary, useTheme, useWaitList } from '@features/App/hooks'
import { GreenButton } from '@features/App/Components'
import useSession from '@hooks/useSession'
import { CustomTextField, PhoneField } from '@features/App/Components/Forms'
import { ChevronLeftIcon } from '@features/App/Components/Icons'
import { RadioElement } from '@features/BoxCheckout/BoxCheckoutMobile'

const Root = styled(Box)(({ theme, customStyles }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  ...customStyles,
}))

const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    width: '100%',
  },
}))

const ContainerForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: 0,
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}))

const NoTicketsCopy = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}))

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.spacing(3),
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
      'looking looking'
      'name phone'
      'email forum'
      'num country'
      'mess mess'
      'captcha captcha'
    `,
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
  },
}))

const StyledGreenButton = styled(props => <GreenButton {...props} />)(({ theme }) => ({
  width: '100%',
  height: '40px',
  color: theme.palette.gray[700],
  fontWeight: '700',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))

const LabelTitle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  font: theme.font.rubik.paragraph.medium,
  fontSize: '16px',
  color: theme.palette.gray[700],
  fontWeight: '500',
  [theme.breakpoints.up('md')]: {
    font: theme.font.roboto.paragraph.small,
    fontSize: '20px',
    fontWeight: '600',
    color: theme.palette.gray[700],
    justifyContent: 'flex-start',
  },
}))

const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

const ContainerCaptcha = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const WaitlistForm = ({
  handleClose,
  eventDefId,
  eventDefName,
  show = true,
  customStyles,
  title = true,
  showNoTicketsCopy = false,
}) => {
  const { glossary } = useGlossary()
  const { userWaitlistSignUp } = useWaitList()
  const { theme } = useTheme()
  const recaptchaRef = useRef()
  const { session } = useSession()
  const { showAlert, showSuccess } = useError()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const [gre, setGre] = useState(null)
  const [Recaptcha, setRecaptcha] = useState(null)
  const [externalMessage, setExternalMessage] = useState('')
  const [externalCountry, setExternalCountry] = useState('')
  const [resetPhoneField, setResetPhoneField] = useState(false)

  const validationSchema = Yup.object({
    type: Yup.string().required(),
    fullName: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    number: Yup.number().required().min(1),
  })

  const formik = useFormik({
    initialValues: {
      type: '1',
      fullName: session ? session.user.fullName : '',
      email: session ? session.user.email : '',
      phone: session ? session.user.phone : '',
      number: 1,
      eventId: eventDefId,
      eventName: eventDefName || '',
    },
    validationSchema,
    onSubmit: async () => {
      try {
        handleWaitlistSignUp()
      } catch (error) {
        showAlert(glossary('SessionError'), 'error')
      }
    },
  })

  const resetForm = () => {
    formik.resetForm()
    setExternalMessage('')
    setExternalCountry('')
    setResetPhoneField(true)
    setTimeout(() => setResetPhoneField(false), 100)
  }

  const handleWaitlistSignUp = useCallback(() => {
    const waitlistData = {
      ...formik.values,
      gre: gre || false,
      phone: formik.values.phone.replaceAll(' ', ''),
      number: parseInt(formik.values.number),
    }
    if (!gre) showAlert(glossary('Captcha'), 'error')
    if (gre) {
      userWaitlistSignUp(waitlistData)
        .then(resp => {
          resetForm()
          showSuccess(glossary('WaitlistSuccess'))
          handleClose()
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setGre(null)
          if (recaptchaRef.current) recaptchaRef.current.reset()
        })
    }
  })

  useEffect(() => {
    if (formik.isValidating && formik.isSubmitting && formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach(key => {
        showAlert(glossary(key), 'error')
      })
    }
  }, [formik, formik.errors])

  useEffect(() => {
    const loadRecaptcha = async () => {
      setRecaptcha((await import('react-google-recaptcha')).default)
    }
    loadRecaptcha().catch(() => {
      showAlert('Error al cargar recaptcha', { variant: 'error' })
    })
  }, [])

  return (
    <Root customStyles={customStyles}>
      {title && (
        <TitleBox>
          {!isMd && <ChevronLeftIcon onClick={handleClose} color={theme.palette.gray[700]} />}
          <LabelTitle>{glossary('WaitList')}</LabelTitle>
        </TitleBox>
      )}

      {showNoTicketsCopy && <NoTicketsCopy>{glossary('WaitListForm.NoTicketsAvailable')}</NoTicketsCopy>}

      <form onSubmit={formik.handleSubmit}>
        <ContainerForm>
          <FormBox>
            <Container sx={{ gridArea: 'looking' }}>
              <Label>{glossary('WhatAreULooking')}</Label>
              <RadioGroup
                row
                name='type'
                value={formik.values.type}
                onChange={e => formik.setFieldValue('type', e.target.value)}
                sx={{ ...(isMd ? { width: '100%' } : {}) }}
              >
                <FormControlLabel
                  value='1'
                  control={<RadioElement sx={{ color: theme.palette.gray[700] }} />}
                  label={<SectionLabel>{glossary('Box')}</SectionLabel>}
                />
                <FormControlLabel
                  value='2'
                  control={<RadioElement sx={{ color: theme.palette.gray[700] }} />}
                  label={<SectionLabel>{glossary('Stall')}</SectionLabel>}
                />
              </RadioGroup>
            </Container>
            <Container sx={{ gridArea: 'name' }}>
              <Label>
                {glossary('Name')}
                {'*'}
              </Label>
              <CustomTextField
                fullWidth
                name='fullName'
                onChange={e => formik.setFieldValue('fullName', e.target.value)}
                value={formik.values.fullName}
                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              />
            </Container>
            <Container sx={{ gridArea: 'phone' }}>
              <PhoneField
                initialLada={'52'}
                initialPhone={formik.values.phone}
                reset={resetPhoneField}
                phoneOnChange={(newLada, newValue) =>
                  formik.setFieldValue('phone', `${newLada ?? ''}${newValue ?? ''}`)
                }
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            </Container>
            <Container sx={{ gridArea: 'email' }}>
              <Label>
                {glossary('Email')}
                {'*'}
              </Label>
              <CustomTextField
                onChange={e => formik.setFieldValue('email', e.target.value)}
                name='email'
                fullWidth
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Container>
            <Container sx={{ gridArea: 'forum' }}>
              <Label>
                {glossary('Event')}
                {'*'}
              </Label>
              <CustomTextField
                name='event'
                fullWidth
                disabled={eventDefName ? true : false}
                value={formik.values.eventName}
                onChange={e => formik.setFieldValue('eventName', e.target.value)}
              />
            </Container>
            <Container sx={{ gridArea: 'num' }}>
              <Label>
                {glossary('SeatsNum')}
                {'*'}
              </Label>
              <CustomTextField
                name='num'
                fullWidth
                onChange={e => formik.setFieldValue('number', e.target.value)}
                value={formik.values.number}
                error={formik.touched.number && Boolean(formik.errors.number)}
              />
            </Container>
            {!show && (
              <Container sx={{ gridArea: 'country' }}>
                <Label>
                  {glossary('ContryAsist')}
                  {'*'}
                </Label>
                <CustomTextField fullWidth onChange={e => setExternalCountry(e.target.value)} value={externalCountry} />
              </Container>
            )}
            {!show && (
              <Container sx={{ gridArea: 'mess' }}>
                <Label>{glossary('LeaveAMessage')}</Label>
                <CustomTextField
                  multiline
                  onChange={e => setExternalMessage(e.target.value)}
                  value={externalMessage}
                  rows={4}
                />
              </Container>
            )}
            <ContainerCaptcha sx={{ gridArea: 'captcha' }}>
              {Recaptcha && (
                <Recaptcha
                  hl={'es'}
                  ref={recaptchaRef}
                  size={'normal'}
                  sitekey={baseConfig.recaptchaSiteKey}
                  onChange={gre => setGre(gre)}
                  onExpired={() => setGre(null)}
                />
              )}
            </ContainerCaptcha>
          </FormBox>
          <ButtonBox>
            <StyledGreenButton type='submit'>{glossary('Send')}</StyledGreenButton>
          </ButtonBox>
        </ContainerForm>
      </form>
    </Root>
  )
}

export default WaitlistForm
